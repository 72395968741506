import { Controller } from "@hotwired/stimulus";
import jQuery from 'jquery'
import isEqual from "lodash/isEqual";

export default class extends Controller {
  static targets = [
    "actions",
    "tableActions",
    "loader",
    "form",
    "hiddenFields",
    "statusMenuContainer",
    "statusMenu",
    "status",
    "deviceTrustMenuContainer",
    "deviceTrustMenu",
    "deviceTrust"
  ];

  dataTable = "";
  selectedWebApps = [];
  selectedStatuses = [];
  selectedStatusesStates = {};
  selectedDeviceTrusts = [];
  selectedDeviceTrustsStates = {};

  connect() {
    this.setActionsVisibility();
    setTimeout(()=> {
      let $tableElm = jQuery('.discovered-apps__table')
      this.dataTable = $tableElm.DataTable()
      this.dataTable.on('select', (e, dt, type, indexes)=> {
        let nodes = dt.rows({ selected: true }).indexes()
        this.setWebApps(nodes);
      });
      this.dataTable.on('deselect', (e, dt, type, indexes)=> {
        let nodes = dt.rows({ selected: true }).indexes()
        this.setWebApps(nodes);
      });
    }, 250);
  }

  setWebApps(nodes) {
    this.selectedWebApps = [];
    this.selectedStatuses = [];
    this.selectedStatusesStates = {};
    this.selectedDeviceTrusts = [];
    this.selectedDeviceTrustsStates = {};

    nodes.each((idx)=> {
      let dataset = jQuery(this.dataTable.column('app:name').nodes()[idx]).find('a')[0].dataset
      this.selectedWebApps[dataset.webAppId] = {
        id:  dataset.webAppId,
        status: dataset.webAppStatus,
        deviceTrust: dataset.webAppDeviceTrust
      };

      this.selectedStatuses.push(dataset.webAppStatus);
      this.selectedDeviceTrusts.push(dataset.webAppDeviceTrust);
    });

    let selectedStatusesCounts = this.selectedStatuses.reduce(function (c, p) {
      c[p] = (c[p] || 0) + 1;
      return c;
    }, Object.create(null));

    this.selectedStatusesStates = Object.fromEntries(
      Object.entries(selectedStatusesCounts)
        .map(([key, val]) => [key, val == Object.keys(this.selectedWebApps).length ? "checked" : "indeterminate"])
    );

    let selectedDeviceTrustsCounts = this.selectedDeviceTrusts.reduce(function (c, p) {
      c[p] = (c[p] || 0) + 1;
      return c;
    }, Object.create(null));

    this.selectedDeviceTrustsStates = Object.fromEntries(
      Object.entries(selectedDeviceTrustsCounts)
        .map(([key, val]) => [key, val == Object.keys(this.selectedWebApps).length ? "checked" : "indeterminate"])
    );

    this.hiddenFieldsTarget.innerHTML = "";
    this.selectedWebApps.forEach((webApp) => {
      let template = `<input multiple="multiple" value="${webApp.id}" type="hidden" name="web_app_ids[]">`;
      this.hiddenFieldsTarget.innerHTML += template
    })

    this.setActionsVisibility();
  }

  setActionsVisibility() {
    if (this.selectedWebApps.length == 0) {
      this.actionsTarget.style.display = "none";
      this.tableActionsTarget.style.display = "";
    } else {
      this.actionsTarget.style.display = "";
      this.tableActionsTarget.style.display = "none";
    }
  }

  hideStatusMenu() {
    if (!this.statusMenuContainerTarget.contains(event.target) && this.statusMenuTarget.classList.contains("open")) {
      this.closeStatusMenu()
    }
  }

  closeStatusMenu() {
    this.statusMenuContainerTarget.classList.remove('open');
    this.statusMenuTarget.classList.remove('open');
    this.submitFormIfStatusChanged();
  }

  openStatusMenu() {
    this.statusMenuContainerTarget.classList.add('open');
    this.statusMenuTarget.classList.add('open');
    this.statusTargets.forEach(status => {
      let statusEnum = status.dataset.statusEnum
      status.classList.remove("indeterminate")
      status.classList.remove("checked")

      if (this.selectedStatusesStates.hasOwnProperty(statusEnum)) {
        status.classList.add(this.selectedStatusesStates[statusEnum])
      }
    })
  }

  toggleStatusMenu() {
    if (this.statusMenuTarget.classList.contains("open")) {
      this.closeStatusMenu()
    } else {
      this.openStatusMenu()
    }
  }

  toggleStatus() {
    this.statusTargets.forEach(status => {
      status.classList.remove("indeterminate")
      status.classList.remove("checked")
    })
    this.currentSelectedStatus = event.currentTarget.statusEnum;
    event.currentTarget.classList.add("checked")
    this.closeStatusMenu();
  }

  submitFormIfStatusChanged() {
    let shouldSubmitForm = false

    let statusState = {}
    this.statusTargets.forEach(status => {
      if (status.classList.contains("checked")) {
        statusState[status.dataset.statusEnum] = "checked"
      } else if (status.classList.contains("indeterminate")) {
        statusState[status.dataset.statusEnum] = "indeterminate"
      }
    })

    if (!isEqual(statusState, this.selectedStatusesStates)) {
      // Only one status can be selected at a time, so if the state doesn't
      // match, that means there is only one status to grab here.
      let newStatus = Object.keys(statusState)[0];
      this.hiddenFieldsTarget.innerHTML += `<input value="${newStatus}" type="hidden" name="triage_status">`
      shouldSubmitForm = true
    }

    if (shouldSubmitForm) {
      this.submitForm()
    }
  }

  submitForm() {
    this.loaderTarget.style.display = "block"
    this.actionsTarget.setAttribute("disabled", "disabled")
    this.formTarget.submit()
  }

  toggleDeviceTrustMenu() {
    if (this.deviceTrustMenuTarget.classList.contains("open")) {
      this.closeDeviceTrustMenu()
    } else {
      this.openDeviceTrustMenu()
    }
  }

  hideDeviceTrustMenu() {
    if (!this.deviceTrustMenuContainerTarget.contains(event.target) && this.deviceTrustMenuTarget.classList.contains("open")) {
      this.closeDeviceTrustMenu();
    }
  }

  closeDeviceTrustMenu() {
    this.deviceTrustMenuTarget.classList.remove('open');
    this.deviceTrustMenuContainerTarget.classList.remove('open');
    this.submitFormIfDeviceTrustChanged();
  }

  openDeviceTrustMenu() {
    this.deviceTrustMenuTarget.classList.add('open');
    this.deviceTrustMenuContainerTarget.classList.add('open');
    this.deviceTrustTargets.forEach(deviceTrust => {
      let deviceTrustEnum = deviceTrust.dataset.deviceTrustEnum
      deviceTrust.classList.remove("indeterminate")
      deviceTrust.classList.remove("checked")

      if (this.selectedDeviceTrustsStates.hasOwnProperty(deviceTrustEnum)) {
        deviceTrust.classList.add(this.selectedDeviceTrustsStates[deviceTrustEnum])
      }
    })
  }

  toggleDeviceTrust() {
    this.deviceTrustTargets.forEach(deviceTrust => {
      deviceTrust.classList.remove("indeterminate")
      deviceTrust.classList.remove("checked")
    })
    this.currentSelectedDeviceTrust = event.currentTarget.deviceTrustEnum;
    event.currentTarget.classList.add("checked")
    this.closeDeviceTrustMenu();
  }

  submitFormIfDeviceTrustChanged() {
    let shouldSubmitForm = false

    let deviceTrustState = {}
    this.deviceTrustTargets.forEach(deviceTrust => {
      if (deviceTrust.classList.contains("checked")) {
        deviceTrustState[deviceTrust.dataset.deviceTrustEnum] = "checked"
      } else if (deviceTrust.classList.contains("indeterminate")) {
        deviceTrustState[deviceTrust.dataset.deviceTrustEnum] = "indeterminate"
      }
    })

    if (!isEqual(deviceTrustState, this.selectedDeviceTrustsStates)) {
      // Only one state can be selected at a time, so if the state doesn't
      // match, that means there is only one device trust to grab here.
      let newDeviceTrust = Object.keys(deviceTrustState)[0];
      this.hiddenFieldsTarget.innerHTML += `<input value="${newDeviceTrust}" type="hidden" name="triage_device_trust">`
      shouldSubmitForm = true
    }

    if (shouldSubmitForm) {
      this.submitForm()
    }
  }
}
