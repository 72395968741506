import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["authHeaderNamePreview", "authHeaderPrefixPreview", "authHeaderName", "authHeaderPrefix"];

  initialize() {
    this.change = debounce(this.change.bind(this), 100);
  }

  change() {
    const authHeaderName = this.authHeaderNameTarget.value;
    const authHeaderPrefix = this.authHeaderPrefixTarget.value;

    this.authHeaderNamePreviewTarget.textContent = authHeaderName;
    this.authHeaderPrefixPreviewTarget.textContent = authHeaderPrefix;
  }
}
