import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    className: String
  };

  toggle() {
    this.element.classList.toggle(this.classNameValue);
  }
}
