import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  static values = { defaultMessage: String };
  static targets = [
    "switch",
    "options",
    "restrictions",
    "message",
    "notAllowed",
    "checkSelection",
    "agentMessage",
    "revertLink"
  ];

  toggle(event) {
    const changeEvent = new Event('change');
    this.switchTarget.checked = !this.switchTarget.checked;
    this.switchTarget.dispatchEvent(changeEvent);
    this.optionsTarget.classList.toggle("hidden");
    this.notAllowedTarget.classList.toggle("hidden");
  }

  toggleRestrictions(event) {
    this.restrictionsTarget.classList.toggle("hidden");
  }

  toggleMessage(event) {
    this.messageTarget.classList.toggle("hidden");
  }

  resetMessage(event) {
    this.agentMessageTarget.value = this.defaultMessageValue;
    this.messageChanged(event);
  }

  previewMessage(event) {
    // Remove any existing params off the URL from previous previews
    var baseURL = event.srcElement.href.split("?")[0]

    // Change the URL, adding the parameter we need to preview
    event.srcElement.href = baseURL + "?preview=" + encodeURIComponent(this.agentMessageTarget.value);
  }

  messageChanged(event) {
    if (this.agentMessageTarget.value == this.defaultMessageValue) {
      this.revertLinkTarget.classList.remove("section-check-selection--content-title-actions-action-revert");
      this.revertLinkTarget.classList.add("section-check-selection--content-title-actions-action-revert-disabled");
    } else {
      this.revertLinkTarget.classList.add("section-check-selection--content-title-actions-action-revert");
      this.revertLinkTarget.classList.remove("section-check-selection--content-title-actions-action-revert-disabled");
    }

  }
}
