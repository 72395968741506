import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  formatEmails(text) {
    // 1. Split on either commas or any whitespace (which includes tabs/newlines).
    let tokens = text.split(/[,\s]+/);

    // 2. Filter out any empty entries (e.g., extra spaces or commas result in empty strings).
    tokens = tokens.filter(Boolean);

    // 3. Join the remaining tokens with comma + space.
    const cleanedText = tokens.join(", ");

    this.inputTarget.value = cleanedText;
  }


  reformatPastedEmails(event) {
    event.preventDefault();
    let pastedText = (event.clipboardData || window.clipboardData).getData('text');
    this.formatEmails(pastedText);
  }

  reformatEmails() {
    this.formatEmails(this.inputTarget.value);
  }
}
