import Alpine from 'alpinejs'
import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["switch", "label"]
  static values = {
    disableMethod: String,
    disableUrl: String,
    enableMethod: String,
    enableUrl: String,
    state: Boolean
  }

  toggle() {
    if (this.switchTarget.checked) {
      this.enable()
    } else {
      this.disable()
    }
  }

  enable() {
    this.submit(this.enableUrlValue, this.enableMethodValue)
    this.stateValue = true
  }

  disable() {
    this.submit(this.disableUrlValue, this.disableMethodValue)
    this.stateValue = false
  }

  submit(endpoint, method) {
    fetch(endpoint, {
      method: method || "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    })
    .then(response => response.json())
    .then(data => {
      if (data.html) {
        Alpine.morph(this.element, data.html);
      }
    })
    .catch(error => console.error("Error:", error));
  }
}
