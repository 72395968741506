import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["autoClickButton", "blockedButton", "submitButton"];
  static values = {
    url: String,
    checkIds: Array,
    snoozableCheckId: Number,
  };

  connect() {
    this.element.addEventListener("submit", this.logSubmit.bind(this));
    if (this.hasAutoClickButtonTarget) {
      this.autoClickButtonTarget.click();
    }
  }

  disconnect() {
    this.element.removeEventListener("submit", this.logSubmit.bind(this));
  }

  async logSubmit(event) {
    event.preventDefault();

    // POST to an internal endpoint to log the sign-in success.
    const csrfToken = document.getElementsByName("csrf-token")[0].content;
    let params = new URLSearchParams({
      snoozable_check_id: this.snoozableCheckIdValue,
    });

    this.checkIdsValue.forEach(element => {
      params.append("check_ids[]", element);
    });

    const response = await fetch(this.urlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "application/json",
      },
      body: params,
    });

    if (response.ok) {
      let data = null;
      // Check if the response includes JSON
      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        try {
          data = await response.json();
        } catch (error) {
          console.warn("JSON parsing failed; data remains null.", error);
        }
      }

      // If JSON data was returne then DT4A is enabled and we should emit an event
      if (data) {
        const dt4aEvent = new CustomEvent("dt4a.device-sign-in-success", { detail: data });
        document.dispatchEvent(dt4aEvent);
      }

      console.info("Sign in success!");
      this.element.submit();
    } else {
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.classList.add("hidden");
        this.blockedButtonTarget.classList.remove("hidden");
      }
      console.error("Error updating auth session.");
    }
  }
}
