// See: https://github.com/hotwired/turbo/pull/863#issuecomment-1470184953
// also, see https://turbo.hotwired.dev/reference/events#turbo%3Aframe-missing for details on the event
document.addEventListener("turbo:frame-missing", function(event) {
  if (typeof bugsnagClient !== 'undefined') {
    bugsnagClient.notify(new Error("Turbo frame missing detected"), function(bugnsagError) {
      bugnsagError.metadata = {
        event_type: event.type,
        event_url: event.detail.response.url
      }
    });
  }
  event.preventDefault()

  // if the event is 500 or a 408 we can skip the visit
  if (event.detail.response && ![500, 408].includes(event.detail.response.status)) {
    event.detail.visit(event.detail.response)
  }
});
