import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import { isVisible } from '../../../global/utils'

export default class extends Controller {
  static targets = ["container", "checkbox", "filterable"];
  static values = {
    deviceIds: Array,
    platforms: Array,
    endpoint: String,
  }

  connect() {
    this.deviceIds = this.deviceIdsValue
    this.platforms = this.platformsValue
    if (this.hasCheckboxTarget) {
      this.toggle()
    }
  }

  stripeList() {
    Array.from(this.filterableTargets)
      .filter(isVisible)
      .forEach((element, index) => {
        if (index % 2 == 0) {
          element.classList.remove("odd")
          element.classList.add("even")
        } else {
          element.classList.remove("even")
          element.classList.add("odd")
        }
      })
  }

  toggle() {
    Rails.ajax({
      type: "PATCH",
      url: this.endpointValue,
      data: `checked=${this.checkboxTarget.checked}`
    })

    if (this.checkboxTarget.checked) {
      this.containerTarget.classList.add("active")
      this.filterableTargets.forEach((filterable, _) => {
        let platforms = JSON.parse(filterable.dataset.platforms)
        let deviceIds = JSON.parse(filterable.dataset.deviceIds || "[]")

        if (!deviceIds.some(r => this.deviceIds.includes(r))) {
          filterable.style.display = 'none'
          if (platforms.some(r => this.platforms.includes(r))) {
            filterable.style.display = ''
          }
        }
        if (!platforms.some(r => this.platforms.includes(r))) {
          filterable.style.display = 'none'
          if (deviceIds.some(r => this.deviceIds.includes(r))) {
            filterable.style.display = ''
          }
        }
      });
    } else {
      this.containerTarget.classList.remove("active")
      this.filterableTargets.forEach(el => el.style.display = '')
    }

    window.dispatchEvent(new CustomEvent('updateEndUserAccessPrivacyCountable', { cancelable: true }))
    // Run it one more time in case this is on page-load and the events haven't
    // been registered. This is a bit of a hack.
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('updateEndUserAccessPrivacyCountable', { cancelable: true }))
    }, 300)

    this.stripeList()
  }
}
