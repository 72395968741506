import {Controller} from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  static values = {url: String, platform: String};
  static targets = [
    "checkSelection",
    "checkbox"
  ];

  connect() {
    if (!this.urlValue) {
      console.log("No remote URL specified");
      return; // Don't try to load tagify if no remote URL is specified
    }

    if (!this.platformValue) {
      console.log("No platform specified");
      return; // Don't try to load tagify if no platform is specified
    }

    // Disable the customized run targetting at load until the user adds a check
    if (this.checkboxTarger) {
      this.checkboxTarget.disabled = true;
    }

    try {
      this.tagify = new Tagify(this.checkSelectionTarget, {
        id: this.nameValue,
        whitelist: [],
        maxTags: 10,
        dropdown: {
          maxItems: 20,
          classname: "tags-look",
          enabled: 0,
          closeOnSelect: false,
        },
        callbacks: {
          change: (e) => this.checkSelection(e)
        },
        templates: {
          tag: (tagData) => {
            return `<tag title='${tagData.value}' contenteditable="false" spellcheck="false" tabindex="-1" class="tagify__tag tagify--noAnim" check_id="${tagData.check_id}" value='${tagData.value}'>
                      <x title="" class="tagify__tag__removeBtn" role="button" aria-label="remove tag"></x>
                      <div>
                        <img src="${tagData.url}" alt='${tagData.value}' height="24" width="24" />
                        <span class="tagify__tag-text">${tagData.value}</span>
                      </div>
                    </tag>`;
          },
          dropdownItem: (tagData) => {
            return `<div check_id="${tagData.check_id}" value='${tagData.value}' class="tagify__dropdown__item">
                      <div>
                        <img src="${tagData.url}" height="24" width="24" />
                        <span class="tagify__dropdown__item-text">${tagData.value}</span>
                      </div>
                    </div>`;
          },
        },
      });
    } catch (error) {
      console.error(`Run Targeting: Unable to load check options for ${this.nameValue}`);
    }

    this.load();
  }

  checkSelection(event) {
    if (!this.checkboxTarger) {
      return;
    }

    const tagifyJSON = this.checkSelectionTarget.value;
    const tagifyArray = JSON.parse(tagifyJSON || "[]");
    if (tagifyArray.length > 0) {
      this.checkboxTarget.disabled = false;
    } else {
      this.checkboxTarget.disabled = true;
    }
  }

  load() {
    fetch(`${this.urlValue}?${new URLSearchParams({for: this.platformValue})}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.tagify.whitelist = data;
      });
  }
}
