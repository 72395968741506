import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  static values = {
    url: String,
    initialTagList: Array,
  };

  static targets = [
    "groupSelector",
    "groupSelectorInput",
    "groupRadioButtonLabelSpan",
    "radioButton"
  ];

  connect() {
    if (this.hasGroupSelectorTarget) {
      this.groupRadioButtonLabelSpanTarget.parentNode.insertAdjacentElement("afterend", this.groupSelectorTarget);
      this.checkGroupSelector();
      this.initializeTagify();
      this.addErrorMessage();
    }
  }

  resetErrorState() {
    this.groupSelectorInputTarget.parentElement.classList.add("field_without_errors")
    this.groupSelectorInputTarget.parentElement.classList.remove("field_with_errors")
  }

  addErrorMessage() {
    let span = document.createElement("span")
    span.classList.add("error")
    span.innerText = "Please specify at least one valid group."
    this.groupSelectorInputTarget.parentElement.appendChild(span);
  }

  markInvalidField() {
    this.groupSelectorInputTarget.parentElement.classList.remove("field_without_errors")
    this.groupSelectorInputTarget.parentElement.classList.add("field_with_errors")
  }

  groupRemoved() {
    if (this.groupSelectorInputTarget.value.length == 0) {
      this.markInvalidField();
    }
  }

  validateAndSubmit(e) {
    e.preventDefault();
    let selectedButton = this.radioButtonTargets.find(btn => btn.checked);

    if (selectedButton.value == "only_registered_owner_or_group_members") {
      if (this.groupSelectorInputTarget.value.length == 0) {
        this.markInvalidField();
        return false;
      }
    }
    this.element.requestSubmit();
  }

  checkGroupSelector() {
    this.resetErrorState();
    let selectedButton = this.radioButtonTargets.find(btn => btn.checked);
    if (selectedButton.value == "only_registered_owner_or_group_members") {
      this.groupSelectorTarget.style.display = "";
    } else {
      this.groupSelectorTarget.style.display = "none";
    }
  }

  initializeTagify() {
    if (!this.urlValue) {
      return; // Don't try to load tagify if no remote URL is specified
    }

    try {
      this.tagify = new Tagify(this.groupSelectorInputTarget, {
        whitelist: this.initialTagListValue,
        enforceWhitelist: true,
        createInvalidTags: false,
        skipInvalid: true, // do not add invalid entries
        maxTags: 10,
        dropdown: {
          searchKeys: ["name"],
          maxItems: 20,
          classname: "tags-look",
          enabled: 0,
          closeOnSelect: false,
        },
        templates: {
          tag: (tagData) => {
            return `<tag title="${tagData.name}" contenteditable="false" spellcheck="false" tabindex="-1" class="tagify__tag tagify--noAnim" value='${tagData.value}'>
                      <x title="" class="tagify__tag__removeBtn" role="button" aria-label="remove tag"></x>
                      <div>
                        <svg class="kolidecon kolidecon-people-group" style="margin-right: 3px;" viewBox="0 0 24 24" version="1.1" width="24" height="24" aria-hidden="true"><path fill-rule="evenodd" d="M18 13a3.5 3.5 0 0 1 3.5 3.5c0 .859-.322 1.637-.837 2.246 1.183.713 2.052 1.877 2.337 3.254h-2c-.422-1.161-1.67-2-3-2-1.328 0-2.576.839-3 2h-2c.286-1.377 1.155-2.541 2.338-3.254A3.466 3.466 0 0 1 14.5 16.5 3.5 3.5 0 0 1 18 13zM6 13a3.5 3.5 0 0 1 3.5 3.5 3.47 3.47 0 0 1-.836 2.246C9.847 19.459 10.716 20.623 11 22H9c-.423-1.161-1.671-2-3-2-1.33 0-2.578.839-3 2H1c.285-1.377 1.154-2.541 2.337-3.254A3.466 3.466 0 0 1 2.5 16.5 3.5 3.5 0 0 1 6 13zm12 2c-.826 0-1.5.673-1.5 1.5S17.174 18 18 18a1.501 1.501 0 0 0 0-3zM6 15c-.827 0-1.5.673-1.5 1.5S5.173 18 6 18s1.5-.673 1.5-1.5S6.827 15 6 15zm6-13a3.5 3.5 0 0 1 3.5 3.5c0 .86-.322 1.637-.836 2.246C15.847 8.459 16.715 9.623 17 11h-2c-.422-1.162-1.67-2-3-2-1.329 0-2.576.838-3 2H7c.285-1.377 1.155-2.541 2.338-3.254A3.464 3.464 0 0 1 8.5 5.5 3.5 3.5 0 0 1 12 2zm0 2c-.826 0-1.5.673-1.5 1.5S11.175 7 12 7a1.501 1.501 0 0 0 0-3z"></path></svg>
                        <span class="tagify__tag-text">${tagData.name}</span>
                      </div>
                    </tag>`;
          },

          dropdownHeader: (_tagData) => {
            return ``;
          },

          dropdownItem: (tagData) => {
            return `<div value='${tagData.value}' class="tagify__dropdown__item">
                      <div>
                        <svg class="kolidecon kolidecon-people-group" style="margin-right: 3px;" viewBox="0 0 24 24" version="1.1" width="24" height="24" aria-hidden="true"><path fill-rule="evenodd" d="M18 13a3.5 3.5 0 0 1 3.5 3.5c0 .859-.322 1.637-.837 2.246 1.183.713 2.052 1.877 2.337 3.254h-2c-.422-1.161-1.67-2-3-2-1.328 0-2.576.839-3 2h-2c.286-1.377 1.155-2.541 2.338-3.254A3.466 3.466 0 0 1 14.5 16.5 3.5 3.5 0 0 1 18 13zM6 13a3.5 3.5 0 0 1 3.5 3.5 3.47 3.47 0 0 1-.836 2.246C9.847 19.459 10.716 20.623 11 22H9c-.423-1.161-1.671-2-3-2-1.33 0-2.578.839-3 2H1c.285-1.377 1.154-2.541 2.337-3.254A3.466 3.466 0 0 1 2.5 16.5 3.5 3.5 0 0 1 6 13zm12 2c-.826 0-1.5.673-1.5 1.5S17.174 18 18 18a1.501 1.501 0 0 0 0-3zM6 15c-.827 0-1.5.673-1.5 1.5S5.173 18 6 18s1.5-.673 1.5-1.5S6.827 15 6 15zm6-13a3.5 3.5 0 0 1 3.5 3.5c0 .86-.322 1.637-.836 2.246C15.847 8.459 16.715 9.623 17 11h-2c-.422-1.162-1.67-2-3-2-1.329 0-2.576.838-3 2H7c.285-1.377 1.155-2.541 2.338-3.254A3.464 3.464 0 0 1 8.5 5.5 3.5 3.5 0 0 1 12 2zm0 2c-.826 0-1.5.673-1.5 1.5S11.175 7 12 7a1.501 1.501 0 0 0 0-3z"></path></svg>
                        <span class="tagify__dropdown__item-text">${tagData.name}</span>
                        <span class="tagify__dropdown__item-count">${tagData.members_count}</span>
                      </div>
                    </div>`;
          },
        },
      });
      this.tagify
        .on('focus',  (e) => { this.resetErrorState() })
        .on('remove', (e) => { this.groupRemoved() })
    } catch (error) {
      console.error(`Unable to load person groups`);
    }

    this.loadTagifyWhitelist();
  }

  loadTagifyWhitelist() {
    fetch(this.urlValue, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.tagify.whitelist = data;
      });
  }
}
